<script setup lang="ts">
import type { EditorialMarqueeStripProps } from './EditorialMarqueeStrip.props'

const props = defineProps<EditorialMarqueeStripProps>()

const localePath = useLocalePath()
const { getCanonicalUrl } = useRouteHelper()
const { breakpoint } = useProjectBreakpoint()
const isMobile = breakpoint.smaller('lg')

const { ts } = useI18n()

const animationRunning = ref(true)
const toggleAnimation = () => {
  animationRunning.value = !animationRunning.value
}

const handleClick = () => {
  if (props.contentsStrip.length === 1) {
    const { link } = props.contentsStrip[0]

    if (link?.target) {
      const isExternal = link.type === 'blank'

      if (isExternal) {
        window.open(link.target, '_blank')
      } else {
        const path = getCanonicalUrl(localePath(link.target))
        navigateTo(path)
      }
    }
  }
}
</script>
<template>
  <div
    class="editorial-marquee-strip py-xl relative flex w-full overflow-hidden"
    :class="[
      { 'cursor-pointer': contentsStrip.length === 1 },
      { 'bg-background-secondary': !bgColorStrip },
    ]"
    :style="{ backgroundColor: bgColorStrip }"
    @mouseenter="!isMobile && toggleAnimation()"
    @mouseleave="!isMobile && toggleAnimation()"
    @click="handleClick"
  >
    <div
      class="marquee gap-x-8xl flex min-w-max"
      :style="{
        'animation-play-state': animationRunning ? 'running' : 'paused',
      }"
    >
      <!-- Content -->
      <div v-for="(content, index) in contentsStrip" :key="`content-${index}`">
        <div
          class="flex items-center"
          :class="{
            'gap-x-8xl': content.leftImgContent || content.rightImgContent,
          }"
        >
          <!-- Left Images Content -->
          <div
            v-if="content.leftImgContent"
            class="gap-x-xxl flex items-center"
          >
            <NuxtPicture
              v-for="(image, imgIndex) in content.leftImgContent"
              :key="`image-${index}-${imgIndex}`"
              provider="cloudinary"
              :src="image.media.secure_url"
              :alt="image.alt"
              fit="cover"
              format="svg"
              height="48px"
            />
          </div>
          <!-- RichText -->
          <UILink
            v-if="content.link"
            class="markdown-content-style text-light-3 md:text-light-2 lg:text-light-1"
            v-bind="content.link"
          />
          <!-- Right Images Content -->
          <div
            v-if="content.rightImgContent"
            class="gap-x-xxl flex items-center"
          >
            <NuxtPicture
              v-for="(image, imgIndex) in content.rightImgContent"
              :key="`image-${index}-${imgIndex}`"
              provider="cloudinary"
              :src="image.media.secure_url"
              :alt="image.alt"
              fit="cover"
              format="svg"
              height="48px"
            />
          </div>
        </div>
      </div>
    </div>
    <button
      v-if="isMobile"
      class="absolute bottom-1 right-2"
      :class="`text-editorial-${iconsColor}`"
      :aria-label="
        animationRunning
          ? ts('aria-labels.editoralModule.marqueeStrip.stopAnimation')
          : ts('aria-labels.editoralModule.marqueeStrip.playAnimation')
      "
      @click="toggleAnimation"
    >
      <DefaultIconsPlayCircle v-if="!animationRunning" />
      <DefaultIconsPauseCircle v-else />
    </button>
  </div>
</template>

<style lang="scss" scoped>
.markdown-content-style :deep(strong) {
  @apply text-medium-3 md:text-medium-2 lg:text-medium-1;
}

.paused {
  animation-play-state: paused;
}
.running {
  animation-play-state: running;
}

.marquee {
  animation: marquee 30s linear infinite;
}

@keyframes marquee {
  0% {
    transform: translate(200vh);
  }
  100% {
    transform: translate(-100%);
  }
}
</style>
